.UserChangePassword {
  .input-form {
    border-right-style: hidden;
    border-bottom-left-radius: 0px !important;
  }

  .input-eye-icon {
    border: 1px solid #b2b2b2;
  }
}
