// If you want to override variables do it here
@import 'font';
@import 'variables';
@import '~animate.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '~react-toggle/style.css';

//Custom
@import 'table';
@import 'button';
@import 'form-controls';
@import 'modal';
@import 'custom';
@import 'scrollbar';

.active-button {
  color: $light;
  background-color: $success;
  border-color: $success;
}

.ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-align-center {
  text-align: center !important;
}

.disable-element {
  pointer-events: none;
  opacity: 0.4;
  box-shadow: none;
}

.clipOverFlow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.page-min-height {
  min-height: calc(100vh - 136px) !important;
}

.tab-min-height {
  height: calc(100vh - 166px) !important;
}

.date-range-field {
  background-image: url('../../assets/img/date_icon.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  max-width: 100%;
  background-color: #fff !important;
  font-weight: normal;
  background-origin: content-box;
  border-color: #dedede;
  background-position: right center;
  border-radius: 8px !important;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #353535;
    opacity: 0.4;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
