/**
 * Set Custom Fonts family and standard font sizes
 * should be written here.
 */

@font-face {
  font-family: 'font-regular';
  src: url(../fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'font-medium';
  src: url(../fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'font-semibold';
  src: url(../fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'font-light';
  src: url(../fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'font-bold';
  src: url(../fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'font-arial';
  src: url(../fonts/Arial-Regular.ttf) format('truetype');
}

/** ==================================
--------------Font family--------------
======================================*/
.font-light {
  font-family: font-light;
}
.font-regular {
  font-family: font-regular;
}
.font-medium {
  font-family: font-medium;
}
.font-semibold {
  font-family: font-semibold;
}
.font-bold {
  font-family: font-bold;
}

/** ==================================
--------------Text Sizes--------------
======================================*/
.text-xxsmall {
  font-size: 0.5714rem;
}
.text-xsmall {
  font-size: 0.7143rem;
}
.text-small {
  font-size: 0.8571rem;
}
.text-regular {
  font-size: 1rem;
}
.text-medium {
  font-size: 1.143rem;
}
.text-large {
  font-size: 1.286rem;
}
.text-xlarge {
  font-size: 1.429rem;
}
.text-hlarge {
  font-size: 1.714rem;
}

/**
PX to REM Converter scale as base 18px
10px = 0.55556rem
11px = 0.61111rem
12px = 0.66667rem
13px = 0.72222rem
14px = 0.77778rem
15px = 0.83333rem
16px = 0.88889rem
17px = 0.94444rem
18px = 1rem (base)
19px = 1.0556rem
20px = 1.1111rem
22px = 1.2222rem
24px = 1.3333rem
26px = 1.4444rem
28px = 1.5556rem
30px = 1.6667rem
32px = 1.7778rem
34px = 1.8889rem
36px = 2rem
38px = 2.1111rem
40px = 2.2222rem
50px = 2.7778rem
60px = 3.3333rem
70px = 3.8889rem
80px = 4.4444rem
90px = 5rem
100px = 5.5556rem
 */
