.full-modal {
  padding: 0 !important;
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    border-radius: 0;
    height: 100%;
    .modal-content {
      height: 100%;
      border: 0 !important;
      border-radius: 0 !important;
    }
  }
}
