/** -----------------------------------------
=========== BOOTSTRAP VARIABLES =========
-------------------------------------------**/
$primary: #328e69 !default;
$secondary: #7f7f7f !default; //sky-blue
$success: #328e69 !default; //turquoise
$info: #0645ad !default; //seafoam-blue
$warning: #ffb757 !default; //pale-orange
$danger: #ff7272 !default; //salmon
$light: #ffffff !default;
$dark: #27353c !default; //blue-grey
$gray: #f6f8ff !default; //gray
$dark-gray: #65677b !default; //dark gray
$label-gray: #7f7f7f !default; //label-gray
$table-first-col: #f5f5f5 !default; //table-first-col
$font-family-base: 'font-regular' !default;
$font-size-base: 1rem !default;
$color-base: #5b7583 !default;
$green: #4d8c6c !default;
$primary-light: #83caa8 !default;
$primary-extra-light: #b3e8ce !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$card-header-size: $font-size-base * 1.714 !default;

$headings-margin-bottom: 0 !default;
$headings-font-family: 'font-bold' !default;
$headings-font-weight: null !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

// Breadcrumbs
$breadcrumb-font-size: 1rem !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: $light !default;
$breadcrumb-divider-color: $secondary !default;
$breadcrumb-active-color: $dark !default;
$breadcrumb-divider: quote('>') !default;

//INPUT
$input-border-radius: 4px !default;
$input-focus-border-color: none !default;
$input-height: 40px !default;
$input-font-size: 1rem !default;
$input-border-width: 1px !default;
$input-border-color: #cccccc !default;
$input-font-family: 'font-regular' !default;
$input-height-mobile: 38px !default;

//button\
$btn-font-family: font-medium !default;
$btn-font-size: 1rem !default;
$btn-font-size-sm: 0.8571rem !default;
$btn-padding-x: 20px !default;
$btn-padding-x-sm: 25px !default;
$btn-line-height: 26px !default;
$btn-line-height-sm: 20px !default;

$nav-tabs-link-active-color: $primary !default;
$pagination-active-bg: #5b7682 !default;
$pagination-color: #5b7682 !default;

//FormLabel
$form-label-color: $color-base !default;

//Link
// $link-color:                  $secondary !default;
$link-decoration: none !default;
$link-hover-decoration: none !default;
$link-color: $info !default;

//Button
$btn-font-size: 1rem !default;

//ToggleButton

//Spinner
$spinner-border-width: 2px !default;

//ProgressBar
$progress-border-radius: 0 !default;
$progress-height: 20px !default;

//ProgressBar

//Table
$table-head-bg: #5b7682 !default;
$table-head-color: #ffffff !default;
$table-border-color: #dcdcdc !default;
$table-tr-color: #f4f9fa !default;
$table-head-blue-light: #f2f5ff !default;
$table-head-blue-medium: #d5e1ff !default;
$table-head-sort-default: #afafaf !default;
$table-font-size: 0.9rem !default;
$table-cell-height: 40px !default;

//Modal
$modal-inner-padding: 1.5rem !default;

/** -----------------------------------------
=========== OTHER CUSTOM VARIABLES =========
-------------------------------------------**/
$page-bg-color: #eff0f5;
// ANIMATIONS
$animation-duration: 0.5s;
$header-height: 55px;
// $header-height: 0;
$header-color: #ffffff;
$breadcrumb-height: 42px;
$text-muted: #989898 !default;
$bg-light-color: #f4f9fa !default;
