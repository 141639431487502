.GooglePlaceAutocomplete {
  z-index: 9999;
  &.autocomplete-dropdown-container {
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 43px;
    padding: 10px;
  }
  .result-row {
    border-bottom: 1px solid #c4c4c4;
    display: flex;
    align-items: center;
    padding: 8px 5px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .result-row:hover {
    background-color: #e3edff;
  }
}
