.ButtonLoading {
  position: relative;
  //display: flex;
  align-items: center;
  justify-content: center;
  .spinner-border {
    position: absolute;
    margin: 0 auto;
  }
}

.icon-action-button {
  background: transparent;
  border: 1px solid #c7d2d8;
  border-radius: 5px;
  padding: 0.8rem;
  cursor: pointer;
  max-height: 40px;
  max-width: 40px;
}

.icon-action-button:hover {
  opacity: 0.9;
  background: #eee;
}
