@import 'variables';
button:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}

button.btn,
div.btn {
  &.btn-wide {
    min-width: 154px;
  }
  @media only screen and (max-width: 570px) {
    &.btn-wide {
      min-width: auto !important;
      width: 100%;
      height: 35px;
      line-height: 1;
    }
  }

  &.dashed-button {
    border: 1px dashed $primary;
    border-radius: 4px;
    background: white;
    color: $primary;
    padding: 5px 30px;
  }

  &.btn-danger {
    color: #ffffff;
  }
}
.group-btns {
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.btn-grey {
  background: #dcdcdc;
}
.btn-circle {
  border-radius: 50px;
  border: none;
  background: #d0e2c0;
  padding: 0px;
  width: 28px;
  height: 28px;
  color: #4d4d4d;
  font-size: 1.1em;
  line-height: 1rem;
}

.btn-modal-close {
  border-radius: 50px;
  border: 1px solid #9f9f9f;
  background: transparent;
  padding: 3px;
  width: 40px;
  height: 40px;
  color: #9f9f9f;
  font-size: 1.8rem;
  line-height: 1.8rem;
}

.btn-refresh {
  padding-left: 10px;
  padding-right: 10px;
  background: #e6e6e6;
  border-radius: 4px;
  color: #acb8bc;
  border: solid 1px #cccccc;
}

.btn-toggle {
  padding: 10px;
  .react-toggle {
    .react-toggle-track {
      width: 46px;
      height: 26px;
    }

    .react-toggle-thumb {
      width: 22px;
      height: 22px;
      top: 2px;
    }
  }
  .react-toggle--checked {
    .react-toggle-thumb {
      width: 22px;
      height: 22px;
      top: 2px;
      left: 23px;
    }
  }
}

.btn-group-spaced.btn-group {
  .btn {
    border-radius: 5px !important;
    margin-right: 10px;
  }
}
