.MySidebar {
  @media only screen and (min-width: 764px) {
    .modal-dialog {
      min-width: 400px;
    }
  }

  /* MODAL FADE LEFT RIGHT BOTTOM */
  &.modal.fade:not(.in).left .modal-dialog {
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
  }
  &.modal.fade:not(.in).right .modal-dialog {
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  &.modal.fade:not(.in).bottom .modal-dialog {
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0);
  }

  &.modal.right .modal-dialog {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    border: 0;
  }

  &.modal.left .modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }

  &.modal.left .modal-dialog.modal-sm {
    max-width: 300px;
  }

  &.modal.left .modal-content,
  &.modal.right .modal-content {
    min-height: 100vh;
    border: 0;
    border-radius: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  .modal-title {
    font-family: font-semibold;
    font-size: 1.146rem;
    font-weight: normal;
    margin-bottom: 5px;
  }
  .modal-footer {
    display: flex;
    flex-wrap: nowrap;
    button {
      width: 100%;
    }
  }
}
