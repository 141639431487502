/*IE*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html,
  body,
  div {
    scrollbar-face-color: #d1d8da;
    scrollbar-track-color: #dcd5d5;
    scrollbar-3dlight-color: #dcd5d5;
    scrollbar-darkshadow-color: #dcd5d5;
    scrollbar-arrow-color: #dcd5d5;
    -ms-overflow-style: -ms-autohiding-scrollbar; // this one will hide scroll-bars after sometime
    scrollbar-width: thin;
  }
}

/* for chrome */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d1d8da;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #93a1a1;
  }
}

/* for firefox */
@-moz-document url-prefix() {
  html,
  body,
  div {
    scrollbar-width: thin;
    scroll-behavior: smooth;
  }
}
